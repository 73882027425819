import {useEffect} from "react"
import Left from "../assets/SVG/left.svg";
import Right from "../assets/SVG/right.svg";
import Shape from "../assets/SVG/shape.svg";
import { Link } from "react-router-dom";
import AOS from 'aos';
import "aos/dist/aos.css";
const FeaturesForm = () => {
   useEffect(() => {
      AOS.init();
      AOS.refresh();

      
      document.addEventListener("mousemove", parralax);
      document.addEventListener("mousemove", parralax2);
  
  
      function parralax(e){
        document.querySelectorAll(".layer").forEach(layer=>{
          const speed = layer.getAttribute("data-speed");
  
          const x = (window.innerWidth = e.pageX*speed)/100;
          const y = (window.innerheight = e.pageY*speed)/100;
  
          layer.style.transform = `translateX(${x}px) translateY(${y}px)`
        })
      }
  
      function parralax2(e){
        document.querySelectorAll(".layer2").forEach(layer=>{
          const speed = layer.getAttribute("data-speed");
  
          const x = (window.innerWidth = e.pageX*speed)/100;
  
          layer.style.transform = `translateX(${x}px)`
        })
      }
  }, [])
    return ( 
        <div>
        <div className="home__header--2">
          <img src={Left} alt=""  className="layer left-1 left-1--small"  data-speed="-0.4"/>
          <img src={Right} alt=""  className="right-1"  data-speed="-1"/> 
          <img src={Shape} alt=""  className="layer shape-1"  data-speed="0.9"/> 
          <h1 className="home__text--2" data-aos="zoom-out" data-aos-duration="1000">How can we help you?</h1>   
      </div>

      <div>
      <form action="">
          <h3 className="bigger-text center-text u-margin-top deep-blue-text">How can we assist you with your staffing needs?</h3>
          <p className="normal-text center-text" style={{color: "gray"}}>Tell us a bit about yourself and how we can help your organization, we will get in touch within 24 hours.</p>
                <div className="grid grid-2 " style={{padding: "1rem", justifyContent: "center", alignItems: "center"}}>
                     <div className="center-hrz--col">
                        <input className="input-textbox " id="firstName" type="text" placeholder="First Name*" style={{marginLeft: 0}}/>
                     </div>
                     <div className="center-hrz--col">
                        <input className="input-textbox " id="lastName" type="text" placeholder="Last Name*" style={{marginLeft: 0}}/>
                     </div>
                  </div>
            

              <div className="row center-hrz">
                              <input type="radio" name="jobType" id="contract" className="radio" required />
                                <label htmlFor="contract" className="radio-label u-margin-bottom" tabIndex="2" >Contract</label>
                                <input type="radio" name="jobType" id="permanent-placement" className="radio" />
                                <label htmlFor="permanent-placement" className="radio-label u-margin-bottom" tabIndex="2" >Permanent Placement</label>
                                <input type="radio" name="jobType" id="temp-to-hire" className="radio" />
                                <label htmlFor="temp-to-hire" className="radio-label u-margin-bottom" tabIndex="2" >Temp-to-hire</label>
            </div> 

            <div className="grid grid-2" style={{padding: 0}}>
                  <div className="center-hrz u-margin-bottom-small">
                        <input className="input-textbox " id="state" type="text" placeholder="Company Name*" style={{marginLeft: 0}} required/>
                     </div>
                    <div className="center-hrz u-margin-bottom-small">
                        <input className="input-textbox " id="phoneNumber" type="text" placeholder="Work phone number*"style={{marginLeft: 0}} required/>
                     </div>
                     <div className="center-hrz u-margin-bottom-small">
                        <input className="input-textbox " id="emailAddress" type="email" placeholder="Email*" style={{marginLeft: 0}} required/>
                     </div>
                     <div className="center-hrz u-margin-bottom-small">
                        <input className="input-textbox " id="address" type="text" placeholder="Zip Code*" style={{marginLeft: 0}} required/>
                     </div>
                     <div className="center-hrz u-margin-bottom-small">
                        <input className="input-textbox " id="city" type="text" placeholder="Job Title*" style={{marginLeft: 0}} required/>
                     </div>

                     <div className="center-hrz u-margin-bottom-small">
                        <input className="input-textbox " id="state" type="text" placeholder="State*" style={{marginLeft: 0}} required/>
                     </div>
              </div>
              


              <div className="center-hrz--col u-margin-top">
                  <p className="normal-text">What are your current staffing needs?</p>
              <textarea  className="input-textbox u-margin-left u-margin-right u-margin-bottom-small support-control" name="cover-letter" id="cover-letter" cols="30" rows="10" placeholder="Request specific roles and quantities or inquire about our workforce solutions." required>
            </textarea>
              </div>

              {/* //captcha will be added when the backend is being done */}
              {/* <div className="center-hrz">
                 <button type="submit" className="button">Submit</button>
              </div> */}
              <div className="center-hrz u-margin-bottom-small">
              <button className="button">Submit</button>
              </div>
              
                  <p className="smaller-text center-text u-margin-bottom">By sumbmitting your information, you are agreeing to our <Link to="/privacypolicy">Privacy Policy</Link> and <Link to="/termsandconditions">Terms and Conditions</Link></p>
              
        </form>
          
      </div>
  </div>
     );
}
 
export default FeaturesForm;