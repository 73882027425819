
//REMEMBER TO SET CALCULATED TO FALSE!!!!!!!!!!!!!!!!!
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import {connect} from "react-redux";
import * as actionCreators from "../../../../redux/actions";
import { bindActionCreators } from 'redux';
import { Redirect } from "react-router";
// import domtoimage from 'dom-to-image';
import firebase from "firebase/app";
import { useToast} from '@chakra-ui/react';
import Loading from "../../../Loading";
import {nanoid} from "nanoid";
import moment from 'moment';
import algoliasearch from "algoliasearch";
import Hit from "./Hit";

// import {InstantSearch, SearchBox} from "react-instantsearch-dom"
// import Hit from "./Hit";


const storage = firebase.storage();
const firestore = firebase.firestore();

const searchClient = algoliasearch(process.env.REACT_APP_ALGOLIA_APP_ID, process.env.REACT_APP_ALGOLIA_SEARCH_KEY);
const searchIndex = searchClient.initIndex("companies");
searchIndex.setSettings({
  searchableAttributes: ["companyName"]
})

const TimePage = (props) => {
    const imagetomake = useRef(null);
    const formRef = useRef(null);
    const [name, setName] = useState("");
    const [calculated, setCalculated] = useState(false);
    const [weekEnding, setWeekEnding] = useState("");
    const [empEmail, setEmpEmail] = useState("");
    const [supEmail, setSupEmail] = useState("");
    const [date, setDate] = useState("");
    const toast = useToast();
    const [isLoading, setIsLoading] = useState(false);
    const [isLoading2, setIsLoading2] = useState(false);
    const [confirmed, setConfirmed] = useState(false);
    const [searchText, setSearchText] = useState("");
//     const [results, setResults] = useState( [{
//       profilePic: "https://firebasestorage.googleapis.com/v0/b/lubin-talent-solutions.appspot.com/o/user.jpg?alt=media&token=bd55e8ab-1851-49d6-9b05-941796903f7a",
//       companyName: "Lubin Talent Solutions",
//       companyPic: "https://firebasestorage.googleapis.com/v0/b/lubin-talent-solutions.appspot.com/o/logo.jpg?alt=media&token=c1b8060b-8297-4579-9c35-1f2773c17a23",
//       uid: "A19nYgwtVYVauXQ22f2zupJjnOz1",
// }]);
const [results, setResults] = useState([]);
    const [selected, setSelected] = useState(null);


  const throwToast = (status, message)=>{
    toast({
        description: message,
        status: status,
        duration: 5000,
        isClosable: true,
        variant:  "top-accent",
        fontSize: '16px',
        position: "top",
      });
}
    const emptyTData = {
      name: "",
      weekEnding: "",
      empEmail : "",
      supEmail : "",
      mon_in: 0,
      mon_out: 0,
      mon_total: 0,
      mon_break: 0,
      tue_in: 0,
      tue_out: 0,
      tue_total: 0,
      tue_break: 0,
      wed_in: 0,
      wed_out: 0,
      wed_total: 0,
      wed_break: 0,
      thur_in: 0,
      thur_out: 0,
      thur_total: 0,
      thur_break: 0,
      fri_in: 0,
      fri_out: 0,
      fri_total: 0,
      fri_break: 0,
      sat_in: 0,
      sat_out: 0,
      sat_total: 0,
      sat_break: 0,
      sun_in: 0,
      sun_out: 0,
      sun_total: 0,
      sun_break: 0,
  }
    const [timeData, setTimeData] = useState(emptyTData);

    const [total, setTotal] = useState(0);
    const [overtime, setOvertime] = useState(0);
    const [alles, setAlles] = useState(0);


    useEffect(async()=>{
      console.log(props.auth);
      if(props.auth.cid !== ""){
        try{
          setIsLoading2(true);
          const snapshot = await firestore.collection("users").doc(props.auth.cid).get();
          setSelected(snapshot.data());
          getTimesheet();
        }catch(e){
          throwToast("error", "Error Getting company");
        }finally{
           setIsLoading2(false);
        }
      }
    },[])

    useEffect(()=>{
      testFunc();
    }, [])






    if(props.auth.type !== "job_seeker"){
        return <Redirect to="/dashboard/account/employer"/>
    }

    const calcTime = (day) => {
      console.log(timeData)
        if(timeData[`${day}_in`] === 0 || timeData[`${day}_out`] === 0){
            return 0;
        }else{
            let { hours: regHours, minutes: regMinutes } = calculateElapsedTime(
                timeData[`${day}_in`],
                timeData[`${day}_out`],
              );
            
              const regTotal = regHours + (regMinutes/ 60) - (parseInt(timeData[`${day}_break`]) / 60);

                setTimeData((prevState)=>({...prevState, [`${day}_total`]: convertDecimalHoursToHoursMinutes(regTotal)}))
              return regTotal;
        }  
      };
      

      const calculateElapsedTime = (start_time, end_time) => {
       
        const startTimeParts = start_time.split(":");
        const endTimeParts = end_time.split(":");
      
        // Create Date objects with today's date and the parsed hours and minutes for both start and end times
        const startDate = new Date();
        startDate.setHours(parseInt(startTimeParts[0], 10));
        startDate.setMinutes(parseInt(startTimeParts[1], 10));
      
        const endDate = new Date();
        endDate.setHours(parseInt(endTimeParts[0], 10));
        endDate.setMinutes(parseInt(endTimeParts[1], 10));
      
        // Check if the end time is earlier than the start time (overnight shift)
        if (endDate < startDate) {
          // Add 24 hours to the end time to account for the overnight shift
          endDate.setDate(endDate.getDate() + 1);
        }
      
        // Calculate the time difference in milliseconds
        const timeDifference = endDate - startDate;
      
        // Convert the time difference to hours and minutes
        const hours = Math.floor(timeDifference / (60 * 60 * 1000));
        const minutes = Math.floor((timeDifference % (60 * 60 * 1000)) / (60 * 1000));
      
        return { hours, minutes };
        
       
      };

      function convertDecimalHoursToHoursMinutes(decimalHours) {
        const hours = Math.floor(decimalHours);
        const minutes = Math.round((decimalHours - hours) * 60);
      
        console.log(hours, minutes);
      
        const hoursStr = hours > 0 ? `${hours}` : "0";
        const minutesStr = minutes > 0 ? `:${minutes}` : ".00";
      
        return `${hoursStr}${minutesStr}`;
      }

      const handleSubmit = (e)=>{
        e.preventDefault();

        const times =
        calcTime("mon") +
        calcTime("tue") +
        calcTime("wed") +
        calcTime("thur") +
        calcTime("fri") +
        calcTime("sat") + 
        calcTime("sun");

        if(times > 40){
          setTotal(convertDecimalHoursToHoursMinutes(40));
          setOvertime(convertDecimalHoursToHoursMinutes(times - 40));
        }else{
          setTotal(convertDecimalHoursToHoursMinutes(times));
          setOvertime(0);
        }
        setAlles(convertDecimalHoursToHoursMinutes(times))
    setCalculated(true);
      }

      const sendTimeSheet= ()=>{
        setIsLoading(true);
        // domtoimage.toBlob(imagetomake.current).then(async(blob)=>{
        //     if(calculated){
        //         //create a nanoid
        //         const tid = nanoid(12);

        //         //upload to firebase storage
        //         let storageRef = storage.ref("timesheets/" + name + "-" + weekEnding);
        //         let uploadTask = storageRef.put(blob);
        //         let downloadURL = "";
        //         uploadTask.on(
        //             "state_changed",
        //             (snapshot) => {
        //               const percentVal = Math.floor(
        //                 (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        //               );
        //               console.log(percentVal + "%");
        //             },
        //             (error) => {
        //              throwToast("error", "Error submitting");
        //             },
        //             () => {
        //               //when you are done
        //               uploadTask.snapshot.ref.getDownloadURL().then(async (url) => {
        //                 downloadURL = url;
        //                 console.log(downloadURL)
        //                 try {
        //                     await firestore.collection("timesheets").doc(tid).set({
        //                       name,
        //                       week_ending: moment(weekEnding).format("MMMM Do YYYY"),
        //                       date: moment(date).format("MMMM Do YYYY"),
        //                       url: downloadURL,
        //                       employee_email: empEmail,
        //                       supervisor_email: supEmail,
        //                       tid,
        //                       companyName: selected.companyName,
        //                       companyPic: selected.companyPic,
        //                       emp_id: props.auth.uid
        //                     })
        //                     console.log("successfully added to timesheets")
        //                     const data = {
        //                       name,
        //                       week_ending: moment(weekEnding).format("MMMM Do YYYY"),
        //                       date: moment(date).format("MMMM Do YYYY"),
        //                       url: downloadURL,
        //                       employee_email: empEmail,
        //                       supervisor_email: supEmail,
        //                       tid,
        //                       companyName: selected.companyName,
        //                       companyPic: selected.companyPic
        //                     };
        //                     console.log(data)
        //                   await fetch(
        //                     "https://lubin-mail-server.onrender.com/api/timesheetlubin",
        //                     {
        //                       headers: {
        //                         Accept: "application/json",
        //                         "Content-Type": "application/json",
        //                       },
        //                       method: "POST",
        //                       body: JSON.stringify(data),
        //                     }
        //                   );
        //                   throwToast("success", "Timesheet successfuly submitted!")
        //                 } catch (e) {
        //                   throwToast("error", "Error submitting timesheet!")
        //                   console.log(e);
        //                 //   return;
        //                 } finally {
        //                     setIsLoading(false);
        //                     throwToast("success", "timesheet successfully sent!");
        //                   formRef.current.reset();
        //                 document.querySelectorAll(".totals").forEach((el) => {
        //                   el.textContent = "";
        //                 });
        //                 }
        //               });
        //             }
        //           );
        //     }else{
        //         throwToast("error", "Please calculate before sending the timesheet")
        //     }
                
        // })
      }     


      const handleSearchText = (e)=>{
        setSearchText(e.target.value);
      }


      const handleSearch = async(queryText) =>{
        try{
          setSelected(null)
          setIsLoading2(true);
          const result = await searchIndex.search(queryText);
          setResults(result.hits);
          console.log(result)
        }catch(e){
          throwToast("error", "Not found!");
          console.log(e);
        }finally{
          setIsLoading2(false);
        }
      }


      const saveTimeSheet = async(e)=>{
        e.preventDefault();
        try{
          setIsLoading(true);
          await firestore.collection("timesheetdata").doc(props.auth.uid).set(timeData);
          throwToast("success", "Timesheet saved!");
        }catch(e){
          throwToast("error", "Could not save timesheet!");
          console.log(e);

        }finally{
          setIsLoading(false);
        }
      }

      const getTimesheet = async()=>{
        try{
          setIsLoading(true);
          const snapshot = await firestore.collection("timesheetdata").doc(props.auth.uid).get();
          console.log(snapshot);
          setTimeData(snapshot.data());
          throwToast("success", "Timesheet retreived!");
        }catch(e){
          throwToast("error", "Could not get timesheet!");
          console.log(e);

        }finally{
          setIsLoading(false);
        }
      }

      const clearTimeSheet = async(e)=>{
        e.preventDefault();
        try{
          setIsLoading(true);
          await firestore.collection("timesheetdata").doc(props.auth.uid).set(emptyTData);
          setTimeData(emptyTData);
          setAlles(0);
          setOvertime(0);
          setTotal(0);
          throwToast("success", "Timesheet saved!");
        }catch(e){
          throwToast("error", "Could not save timesheet!");
          console.log(e);
        }finally{
          setIsLoading(false);
        }
      }

      const testFunc = async()=>{
        try{
          const snapshot = await firestore.collection("users").where("type", "==", "job_seeker").get();
          snapshot.forEach(async(doc)=>{
            await firestore.collection("timesheetdata").doc(doc.id).set({
              ...emptyTData
            })
          })
        }catch(e){
          console.log(e)
        }
      }
      

    return ( 
        <div className="screen">
               <h3 className="screen__header">Time</h3>

               <div className="u-margin-top">
                {
                  !confirmed ?    
                  <div className="animate">
                    <h1 className="sub-header-text">Search for your company</h1>
                    <div style={{display: "flex"}}>
                    <input sstyle={{marginLeft: 0}} type="text" placeholder="company name" className="input-textbox" id="ais-SearchBox-input" onChange={handleSearchText} value={searchText} onKeyDown={(e)=>{
                      if(e.key === "Enter"){
                        handleSearch(searchText);
                      }
                    }}/> 
                    <div style={{display: "flex", alignItems: "center"}}>
                    <button className="button" onClick={()=>handleSearch(searchText)}>Search</button>
                    </div>
                    </div>

                    {
                      selected ?
                      <>
                      
                      <div className="selected__img u-margin-bottom-small"  style={{backgroundImage: `url(${selected.companyPic})`}}></div>
                      <h2 className="bigger-text bold-text u-margin-bottom"><span className="bigger-text">{selected.companyName}</span></h2>
                      <p className="normal-text u-margin-bottom-small">If this is your company, click on confirm below</p>
                      <button style={{marginRight: "10px"}}  className="button" onClick={()=>{setConfirmed(true)}}>Confirm</button>
                      <button  className="button button--red" onClick={async()=>{
                          setIsLoading2(true);
                        setSelected(null);
                        props.setAuth2({
                          ...props.auth,
                          cid: ""
                        });
                        try{
                          const snapshot = await firestore.collection("users").doc(props.auth.uid).update({
                              cid: ""
                          });
                        }catch(e){
                          throwToast("error", "Error updating company");
                        }finally{
                          setIsLoading2(false);
                        }
                        }}>Back</button>
  
                      </> :
                      isLoading2 ? <Loading/> : 
                      results.length == 0 ?
                      <p className="normal-text">No results</p> :
                      <>
                      <p className="bigger-text u-margin-bottom u-margin-top">{results.length} Results</p>
                      {
                      results.map((el)=><Hit hit={el} key={el} setSelected={setSelected} throwToast={throwToast} uid={props.auth.uid} setAuth={props.setAuth2} auth={props.auth}/>)
                      }
                      </>
                    }

                    {/* <InstantSearch searchClient={searchClient} indexName="companies">
                    <SearchBox classNames={{input: "input-textbox"}}/>
                    </InstantSearch>  */}

                  </div> :

                  timeData ? 
                  <div style={{overflow: "scroll"}}  className="animate">
                    <div className="selected__img u-margin-bottom-small"  style={{backgroundImage: `url(${selected.companyPic})`}}></div>
                      <h2 className="bigger-text bold-text u-margin-bottom"><span className="bigger-text">{selected.companyName}</span></h2>
                    <button className="button" onClick={()=>{setConfirmed(false)}}>Go Back</button>
                  <form action="" id="tableForm" className="normal-text" onSubmit={handleSubmit} ref={formRef}>
                  <div id="imagetomake" ref={imagetomake} className="u-padding-bottom">
                      <div className=" u-margin-bottom-medium u-margin-top-small">
                          <div className="col-md-6">
                              <div className="form-floating">
                                  <input type="text" className="input-textbox u-nomargin" name="name" id="name" placeholder="Your Name" required value={name} onChange={e=>setName(e.target.value)}/> <br/>
                                  <label htmlFor="name">Name*</label>
                              </div>
                          </div> <br/>
                          <div className="col-md-3">
                              <div className="form-floating">
                                  <input type="date" className="input-textbox u-nomargin smaller-text" id="week_ending" placeholder="Week Ending" required value={weekEnding} onChange={e=>setWeekEnding(e.target.value)}/> <br/>
                                  <label htmlFor="week_ending">Week Ending*</label>
                              </div>
                          </div> <br/>
                          <div className="col-md-3">
                              <div className="form-floating">
                                  <input type="date" className="input-textbox u-nomargin smaller-text" id="current_date" placeholder="Date" required value={date} onChange={e=>setDate(e.target.value)}/> <br/>
                                  <label htmlFor="current_date">Date*</label>
                              </div>
                          </div> <br/>
                          <div className="col-md-6">
                              <div className="form-floating">
                                  <input type="email" className="input-textbox u-nomargin" name="email" id="email" placeholder="Email" required value={empEmail} onChange={e=>setEmpEmail(e.target.value)}/> <br/>
                                  <label htmlFor="email">Your Email*</label>
                              </div>
                          </div> <br/>
                          <div className="col-md-6">
                              <div className="form-floating">
                                  <input type="email" className="input-textbox u-nomargin" name="email" id="email" placeholder="Email" required value={supEmail} onChange={e=>setSupEmail(e.target.value)}/> <br/>
                                  <label htmlFor="email">Your Supervisor's Email*</label>
                              </div>
                          </div>
                      </div>
                      <table id="final_table">
                          <thead>
                              <tr>
                                  <th className="center-text">Day</th>
                                  <th className="center-text">Starting Time</th>
                                  <th className="center-text break_header">Ending Time</th>
                                  <th className="center-text">Break Deduction (minutes)</th>
                                  <th className="center-text">Total Time (h:mm)</th>
                              </tr>
                          </thead>
                          <tbody>
                              <tr>
                                  <td>Monday</td>
                                  <td><input type="time" className="input-time" id="mon-reg-in" value={timeData.mon_in} onChange={(e)=>setTimeData(prevState=>({...prevState, mon_in: e.target.value}))}/></td>
                                  <td><input type="time" className="input-time" id="mon-reg-out" value={timeData.mon_out} onChange={(e)=>setTimeData(prevState=>({...prevState, mon_out: e.target.value}))}/></td>
                                  <td><input type="number" className="breaktime" placeholder="time in minutes" id="mon-reg-break" value={timeData.mon_break} onChange={(e)=>setTimeData(prevState=>({...prevState, mon_break: e.target.value}))}/></td>
                                  <td id="mon-reg-total" className="totals">{timeData.mon_total}</td>
                              </tr>
                              <tr>
                                  <td>Tuesday</td>
                                  <td><input type="time" className="input-time" id="tue-reg-in" value={timeData.tue_in} onChange={(e)=>setTimeData(prevState=>({...prevState, tue_in: e.target.value}))}/></td>
                                  <td><input type="time" className="input-time" id="tue-reg-out" value={timeData.tue_out} onChange={(e)=>setTimeData(prevState=>({...prevState, tue_out: e.target.value}))}/></td>
                                  <td><input type="number" className="breaktime" placeholder="time in minutes" id="tue-reg-break" value={timeData.tue_break} onChange={(e)=>setTimeData(prevState=>({...prevState, tue_break: e.target.value}))}/></td>
                                  <td id="tue-reg-total" className="totals">{timeData.tue_total}</td>
                              </tr>
                              <tr>
                                  <td>Wednesday</td>
                                  <td><input type="time" className="input-time" id="wed-reg-in" value={timeData.wed_in} onChange={(e)=>setTimeData(prevState=>({...prevState, wed_in: e.target.value}))}/></td>
                                  <td><input type="time" className="input-time" id="wed-reg-out" value={timeData.wed_out} onChange={(e)=>setTimeData(prevState=>({...prevState, wed_out: e.target.value}))}/></td>
                                  <td><input type="number" className="breaktime" placeholder="time in minutes" id="wed-reg-break" value={timeData.wed_break} onChange={(e)=>setTimeData(prevState=>({...prevState, wed_break: e.target.value}))}/></td>
                                  <td id="wed-reg-total" className="totals">{timeData.wed_total}</td>
                              </tr>
                              <tr>
                                  <td>Thursday</td>
                                  <td><input type="time" className="input-time" id="thur-reg-in" value={timeData.thur_in} onChange={(e)=>setTimeData(prevState=>({...prevState, thur_in: e.target.value}))}/></td>
                                  <td><input type="time" className="input-time" id="thur-reg-out" value={timeData.thur_out} onChange={(e)=>setTimeData(prevState=>({...prevState, thur_out: e.target.value}))}/></td>
                                  <td><input type="number" className="breaktime" placeholder="time in minutes" id="thur-reg-break" value={timeData.thur_break} onChange={(e)=>setTimeData(prevState=>({...prevState, thur_break: e.target.value}))}/></td>
                                  <td id="thur-reg-total" className="totals">{timeData.thur_total}</td>
                              </tr>
                              <tr>
                                  <td>Friday</td>
                                  <td><input type="time" className="input-time" id="fri-reg-in" value={timeData.fri_in} onChange={(e)=>setTimeData(prevState=>({...prevState, fri_in: e.target.value}))}/></td>
                                  <td><input type="time" className="input-time" id="fri-reg-out" value={timeData.fri_out} onChange={(e)=>setTimeData(prevState=>({...prevState, fri_out: e.target.value}))}/></td>
                                  <td><input type="number" className="breaktime" placeholder="time in minutes" id="fri-reg-break" value={timeData.fri_break} onChange={(e)=>setTimeData(prevState=>({...prevState, fri_break: e.target.value}))}/></td>
                                  <td id="fri-reg-total" className="totals">{timeData.fri_total}</td>
                              </tr>     
                              <tr>
                                  <td>Saturday</td>
                                  <td><input type="time" className="input-time" id="sat-reg-in" value={timeData.sat_in} onChange={(e)=>setTimeData(prevState=>({...prevState, sat_in: e.target.value}))}/></td>
                                  <td><input type="time" className="input-time" id="sat-reg-out" value={timeData.sat_out} onChange={(e)=>setTimeData(prevState=>({...prevState, sat_out: e.target.value}))}/></td>
                                  <td><input type="number" className="breaktime" placeholder="time in minutes" id="sat-reg-break" value={timeData.sat_break} onChange={(e)=>setTimeData(prevState=>({...prevState, sat_break: e.target.value}))}/></td>
                                  <td id="sat-reg-total" className="totals">{timeData.sat_total}</td>
                              </tr>
                              <tr>
                                  <td>Sunday</td>
                                  <td><input type="time" className="input-time" id="sun-reg-in" value={timeData.sun_in} onChange={(e)=>setTimeData(prevState=>({...prevState, sun_in: e.target.value}))}/></td>
                                  <td><input type="time" className="input-time" id="sun-reg-out" value={timeData.sun_out} onChange={(e)=>setTimeData(prevState=>({...prevState, sun_out: e.target.value}))}/></td>
                                  <td><input type="number" className="breaktime" placeholder="time in minutes" id="sun-reg-break" value={timeData.sun_break} onChange={(e)=>setTimeData(prevState=>({...prevState, sun_break: e.target.value}))}/></td>
                                  <td id="sun-reg-total" className="totals">{timeData.sun_total}</td>
                              </tr>
                              <tr>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td className="break_inputs"><span className="bold-text bigger-text right-text">Regular Hours</span></td>
                                  <td id="reg-sum" className="totals">{total}</td>
                              </tr>
                              <tr>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td className="break_inputs"><span className="bold-text bigger-text right-text">Overtime Hours</span></td>
                                  <td id="reg-sum" className="totals">{overtime}</td>
                              </tr>
                              <tr>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td className="break_inputs"><span className="bold-text bigger-text right-text">Total Hours</span></td>
                                  <td id="reg-sum" className="totals">{alles}</td>
                              </tr>
                          </tbody>
                      </table>
                      </div>

                      <div className="u-margin-top-small u-margin-bottom-small" >
                        <button className="button" style={{marginRight: "10px"}} onClick={saveTimeSheet}>Save Timesheet</button> 
                        <button className="button" onClick={clearTimeSheet}>Clear Timesheet</button> 
                      </div>

                      <div className="u-margin-top-small u-margin-bottom-small">
                      <p>Please fill in the fields(Name, week ending and date) and press the calculate button before sending the timesheet!</p>
                          <button type="submit" id="testbtnn" className="button">Calculate</button>
                      </div>
                  </form>
                  {
                      isLoading ? <Loading/> :
                  <button type="submit" id="testbtnn" className="button" onClick={sendTimeSheet}>Send Timesheet</button>

                  }
                  </div> : null
                }
             
               </div>

        </div>
     );
}
 

const mapStateToProps= state=>({
    auth: state.auth
  })
  
  const mapDispatchToProps = dispatch =>{
    return bindActionCreators(actionCreators, dispatch);
  }


export default connect(mapStateToProps, mapDispatchToProps)(TimePage);