import React, {useEffect, useState, Suspense,lazy} from "react";
import { Route} from "react-router";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Logo from "./assets/SVG/logodarkbg.svg";
import Logo2 from "./assets/SVG/logolightbg.svg";
import ScrollToTop from "./functionality/ScrollToTop";
import {useLocation} from "react-router-dom";
import AuthContextProvider from "./context/AuthContext";
import Loading from "./components/Loading";
import Time from "./components/Dashboard/EmployeeFeatures/Time/Time";
import FeaturesForm from "./components/FeaturesForm";

const UnderConstruction = lazy(()=>import("./components/UnderConstruction"));
const QuickApply = lazy(() => import('./components/QuickApply'));
const QuickApplyByDept = lazy(()=>import("./components/QuickApplyByDept"));
const Home = lazy(()=>import("./components/Home"));
const About = lazy(()=>import("./components/About"));
const Contact = lazy(()=>import("./components/Contact"));
const SignUp = lazy(()=>import("./components/SignUp"));
const Login = lazy(()=>import("./components/Login"));
const Employee = lazy(()=>import("./components/signup/Employee"));
const Employer =  lazy(()=>import("./components/signup/Employer"));
const Forgotpassword = lazy(()=>import("./components/ForgotPassword"));
const JobBoard = lazy(()=>import("./components/JobBoard/JobBoard"));
const Features = lazy(()=>import("./components/Features"));
const Blog = lazy(()=>import("./components/Blog/Blog"));
const Apply = lazy(()=>import("./components/Apply"));
const Dashboard = lazy(()=>import("./components/Dashboard/Dashboard"));
const Terms = lazy(()=>import("./components/TermsAndConditions"));
const Privacy = lazy(()=>import("./components/Privacy"));
const PrivacyContact = lazy(()=>("./components/PrivacyContact"));

const ForEmployers = lazy(()=>import("./components/ForEmployers"));
const Speacializations = lazy(()=>import("./components/OurSpecializations"));
const Resetpassword = lazy(()=>import("./components/ResetPassword"));
const JobPage = lazy(()=>import("./components/JobPage"));
const Options = lazy(()=>import("./components/expertise/options"));
const RequestForm = lazy(()=>import("./components/expertise/RequestForm"));
const ExpertisePage = lazy(()=>import("./components/expertise/ExpertisePage"));
const SignTimeSheet = lazy(()=>import("./components/SignTimesheet"));


function App() {
  const [barColorState, setBarColorState] = useState("#ffffff");
  const location = useLocation();
  const scrollToTop = ()=>{
    window.scrollTo(0,0);
   }

  useEffect(()=>{
    const scrollFunction = ()=>{
      const nav = document.querySelector(".nav");
      const navMobile = document.querySelector(".nav--mobile");
      const navLinks = document.querySelectorAll(".nav__link");
      const getStarted = document.querySelector(".button--getstarted");
      const companyLogos = document.querySelectorAll(".nav__logo");
      
if(nav){
  if(window.scrollY){
    nav.classList?.add("visible");
    navMobile.classList.add("visible");
    navLinks.forEach(el=>{
      el.classList.add("visible");
    });
    getStarted.classList.add("visible");

    companyLogos.forEach(logo=>{
      logo.src = Logo2;
    })
    setBarColorState("#000000");
  }else{
   nav && nav.classList?.remove("visible");
    navMobile.classList.remove("visible");

    navLinks.forEach(el=>{
      el.classList.remove("visible");
    });
    getStarted.classList.remove("visible");
    companyLogos.forEach(logo=>{
      logo.src = Logo;
    });
    setBarColorState("#ffffff");
  }
}
    

    }
    window.addEventListener("scroll", ()=>{
      scrollFunction();
    })

    return ()=>{
      window.removeEventListener("scroll", scrollFunction)
    }
  }, []);


  
  return (
    <AuthContextProvider>
    <div className="App">
      {
        location.pathname.startsWith("/dashboard") ?
        (
          <Suspense fallback={<div><Loading/></div>}><Route path="/dashboard" component={Dashboard}/></Suspense>) : 
        (
          <>
          <Suspense fallback={<div><Loading/></div>}>
          <Navbar barColor={barColorState}/>

            <Route exact path="/" component={Home}/>
            <Route path="/about" component={About}/>
            <Route path="/contact" component={Contact}/>
            <Route exact path="/signup" component={SignUp}/>
            <Route exact path="/login" component={Login}/>
            <Route path="/signup/employee" component={Employee}/>
            <Route path="/signup/employer" component={Employer}/>
            <Route path="/forgotpassword" component={Forgotpassword}/>
            <Route path="/jobs" component={JobBoard}/>
            <Route path="/features" component={Features}/>
            <Route exact path="/blog" component={Blog}/>
            <Route path="/apply/:id" component={Apply}/>
            <Route path="/termsandconditions" component={Terms}/>
            <Route path="/privacypolicy" component={Privacy}/>
            <Route path="/privacyrequest" component={PrivacyContact}/>
            <Route path="/featuresform" component={FeaturesForm}/>
            <Route path="/foremployers" component={ForEmployers}/>
            <Route exact path="/expertise" component={Speacializations}/>
            <Route path="/resetpassword" component={Resetpassword}/>
            <Route exact path="/jobsapply" component={QuickApply}/>
            <Route exact path="/jobsapply/:dept" component={QuickApplyByDept}/>
            <Route path="/job/:id" component={JobPage}/>
            <Route path="/expertise/:expertise" component={ExpertisePage}/>
            <Route path="/options/:department" component={Options}/>
            <Route path="/requestform/:department" component={RequestForm}/>
            <Route path="/underconstruction" component={UnderConstruction}/>
            <Route path="/dashboard/time" component={Time}/>
            <Route path="/sign/:tid" component={SignTimeSheet}/>

            {/* <QuickApply/> */}
            <ScrollToTop scrollToTop={scrollToTop}/>
         <Footer/>
         </Suspense>
          </>
        )
      } 
    </div>
    </AuthContextProvider>

  );
}

export default App;
